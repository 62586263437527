import React, { useState, useEffect, useRef } from 'react';
import { doc, getDoc, setDoc, storage, firestore, ref, uploadBytes, getDownloadURL, collection, addDoc, auth, serverTimestamp, onAuthStateChanged } from './firebase-config';
import './UploadMediaModal.css'; // Your custom styles
import './FABUploadMedia.css'; // Your custom styles
import { useLocation } from 'react-router-dom';

function UploadMediaModal({ addMedia, currentPrompt, onClose }) {
  const [file, setFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [caption, setCaption] = useState(""); // Caption/comment
  const [userId, setUserId] = useState(null); // User ID from Firebase Auth
  const [displayName, setDisplayName] = useState(""); // User's display name
  const fileInputRef = useRef(null); // Reference to the file input element
  const [loading, setLoading] = useState(false); // To prevent multiple submissions
  const location = useLocation();
  const eventCode = location.pathname.split('/')[1];

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
        setDisplayName(user.displayName);
      } else {
        setUserId(null);
        setDisplayName("");
      }
    });

    return () => unsubscribe();
  }, []);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
  
          const MAX_WIDTH = 1080;
          const MAX_HEIGHT = 1080;
          let width = img.width;
          let height = img.height;
  
          if (width > height) {
            if (width > MAX_WIDTH) {
              height = Math.round((height * MAX_WIDTH) / width);
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width = Math.round((width * MAX_HEIGHT) / height);
              height = MAX_HEIGHT;
            }
          }
  
          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);
  
          canvas.toBlob(
            (blob) => {
              const compressedFile = new File([blob], selectedFile.name, {
                type: 'image/jpeg',
                lastModified: Date.now(),
              });
              setFile(compressedFile);
              const previewUrl = URL.createObjectURL(blob);
              setPreviewUrl(previewUrl);
            },
            'image/jpeg',
            0.8 // Compression quality (0.8 is a good balance between quality and size)
          );
        };
      };
    }
  };
  
  const handleCaptionChange = (e) => {
    setCaption(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (file && userId) {
      setLoading(true);

      try {
        const uniqueId = `${Date.now()}-${Math.floor(Math.random() * 1000)}`;
        const storagePath = `events/${eventCode}/media/${uniqueId}`;
        const storageRef = ref(storage, storagePath);
        
        await uploadBytes(storageRef, file);
        const fileUrl = await getDownloadURL(storageRef);

        const newMedia = {
          prompt: currentPrompt,
          displayname: displayName,
          caption: caption,
          src: fileUrl,
          name: file.name,
          timestamp: serverTimestamp(),
          userId: userId,
          type: 'media',
          uniqueId: uniqueId,
        };

        const docPath = `events/${eventCode}/media`;
        const docRef = await addDoc(collection(firestore, docPath), newMedia);
        console.log('Document written with ID:', docRef.id);
        addMedia(newMedia);

        // Check if user profile exists in events/eventCode/profiles
        const profileDocRef = doc(firestore, `events/${eventCode}/profiles`, userId);
        const profileSnapshot = await getDoc(profileDocRef);

        if (!profileSnapshot.exists()) {
          // If no profile exists, set this image as the profile picture
          await setDoc(profileDocRef, {
            photoURL: fileUrl,
          });
          console.log('Profile created with first photo as profile picture:', fileUrl);
        }

        setFile(null);
        setPreviewUrl(null);
        setCaption("");
        fileInputRef.current.value = "";
        onClose(); // Close the modal after successful upload
      } catch (error) {
        console.error('Error uploading file:', error);
      } finally {
        setLoading(false);
      }
    } else {
      console.error('No file selected or user not logged in.');
    }
  };

  return (
    <div className="uploadMedia-modal-overlay">
      <div className="uploadMedia-modal-content">
        <button className="media-modal-close" onClick={onClose}>
          <span className="material-icons">close</span>
        </button>
        
          <form onSubmit={handleSubmit} className="upload-media-form">
            <label className="custom-file-upload">
              <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                ref={fileInputRef}
                required
              />
              Choose Image
            </label>

            {previewUrl && (
              <div className="image-preview-container">
                <img src={previewUrl} alt="Preview" className="image-preview" />
              </div>
            )}

            <textarea
              className="caption-input"
              placeholder="Write a caption..."
              value={caption}
              onChange={handleCaptionChange}
            />

            <button type="submit" className="media-submit-btn" disabled={loading}>
              {loading ? 'Posting...' : 'Post'}
            </button>
          </form>
      </div>
    </div>
  );
}

export default UploadMediaModal;
