import React, { useState, useEffect, useRef } from "react";
import {
  doc,
  getDoc,
  setDoc,
  storage,
  firestore,
  ref,
  uploadBytes,
  getDownloadURL,
  collection,
  addDoc,
  auth,
  serverTimestamp,
  onAuthStateChanged,
} from "./firebase-config";
import "./Camera.css";
import { useLocation } from "react-router-dom";

function Camera({ addMedia, onClose, currentPrompt }) {
  const [file, setFile] = useState(null); // Holds the preview data URL
  const [caption, setCaption] = useState("");
  const [userId, setUserId] = useState(null);
  const [displayName, setDisplayName] = useState("");
  const inputRef = useRef(null);
  const canvasRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const eventCode = location.pathname.split("/")[1];

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
        setDisplayName(user.displayName);
      } else {
        setUserId(null);
        setDisplayName("");
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  }, []);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
  
    if (file) {
      const fileReader = new FileReader();
      fileReader.onload = async () => {
        const fileUrl = fileReader.result;
  
        try {
          // Apply filter and generate a preview
          const previewUrl = await applySvgFilterToCanvas(fileUrl);
          setFile(previewUrl); // Update file state with the filtered image
        } catch (error) {
          console.error("Error generating preview:", error);
        }
      };
      fileReader.readAsDataURL(file);
    }
  };  

  const svg = (width, height) => `
    <svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}" viewBox="0 0 ${width} ${height}">
      <filter id="vintage">
        <feColorMatrix
          type="matrix"
          values="0.3588 0.7044 0.1368 0 0
                  0.2990 0.5870 0.1140 0 0
                  0.2392 0.4696 0.0912 0 0
                  0 0 0 1 0"
        />
        <feComponentTransfer>
          <feFuncR type="linear" slope="1.1" intercept="-0.1" />
          <feFuncG type="linear" slope="1.1" intercept="-0.1" />
          <feFuncB type="linear" slope="1.1" intercept="-0.1" />
        </feComponentTransfer>
        <feComponentTransfer>
          <feFuncR type="linear" slope="1.5" />
          <feFuncG type="linear" slope="1.4" />
          <feFuncB type="linear" slope="1.3" />
        </feComponentTransfer>
        <feTurbulence
          type="fractalNoise"
          baseFrequency="0.6"
          numOctaves="3"
          result="noise"
        />
        <feBlend mode="overlay" in="SourceGraphic" in2="noise" />
      </filter>
      <image href="${file}" x="0" y="0" width="${width}" height="${height}" style="filter: url(#vintage);" />
    </svg>
  `;

  const applySvgFilterToCanvas = (fileUrl) => {
    return new Promise((resolve, reject) => {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
  
      const img = new Image();
      img.crossOrigin = "anonymous";
      img.src = fileUrl;
  
      img.onload = () => {
        const MAX_WIDTH = 1080;
        const MAX_HEIGHT = 1080;
        let width = img.width;
        let height = img.height;
  
        if (width > height) {
          if (width > MAX_WIDTH) {
            height = Math.round((height * MAX_WIDTH) / width);
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width = Math.round((width * MAX_HEIGHT) / height);
            height = MAX_HEIGHT;
          }
        }
  
        canvas.width = width;
        canvas.height = height;
  
        // Draw the original image
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(img, 0, 0, width, height);
  
        // Create the SVG filter
        const svgMarkup = svg(width, height);
        const svgBlob = new Blob([svgMarkup], { type: "image/svg+xml;charset=utf-8" });
        const svgUrl = URL.createObjectURL(svgBlob);
  
        // Load the SVG as an image
        const svgImg = new Image();
        svgImg.crossOrigin = "anonymous";
        svgImg.src = svgUrl;
  
        svgImg.onload = () => {
          ctx.drawImage(svgImg, 0, 0, width, height);
  
          // Generate the data URL for the preview
          const previewUrl = canvas.toDataURL("image/png");
          resolve(previewUrl);
        };
  
        svgImg.onerror = (err) => {
          console.error("Error loading SVG into canvas:", err);
          reject(err);
        };
      };
  
      img.onerror = (err) => {
        console.error("Error loading image:", err);
        reject(err);
      };
    });
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!file || !userId) {
      console.error("No file selected or user not logged in.");
      return;
    }
  
    setLoading(true);
  
    try {
      const canvas = canvasRef.current;
  
      canvas.toBlob(async (blob) => {
        const uniqueId = `${Date.now()}-${Math.floor(Math.random() * 1000)}`;
        const storagePath = `events/${eventCode}/media/${uniqueId}`;
        const storageRef = ref(storage, storagePath);
  
        await uploadBytes(storageRef, blob); // Upload filtered image blob
        const fileUrl = await getDownloadURL(storageRef);
  
        const newMedia = {
          displayname: displayName,
          caption: caption,
          src: fileUrl,
          timestamp: serverTimestamp(),
          userId: userId,
          type: "media",
          uniqueId: uniqueId,
        };
  
        const docPath = `events/${eventCode}/media`;
        await addDoc(collection(firestore, docPath), newMedia);
        addMedia(newMedia);
  
        const profileDocRef = doc(firestore, `events/${eventCode}/profiles`, userId);
        const profileSnapshot = await getDoc(profileDocRef);
  
        if (!profileSnapshot.exists()) {
          await setDoc(profileDocRef, {
            photoURL: fileUrl,
          });
        }
  
        onClose();
      });
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <div className="camera-modal-overlay">
      <div className="camera-modal-content">
        <button className="media-modal-close" onClick={onClose}>
          <span className="material-icons">close</span>
        </button>

        <div className="camera-preview-section">
          <div className="image-preview-container">
            {file ? (
              <img
                src={file}
                alt="Preview"
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                }}
              />
            ) : (
              <p>Loading preview...</p>
            )}
            <canvas ref={canvasRef} style={{ display: "none" }} />
          </div>

          <textarea
            className="caption-input"
            placeholder="Write a caption..."
            value={caption}
            onChange={(e) => setCaption(e.target.value)}
          />
          <button
            type="submit"
            className="media-submit-btn"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? "Posting..." : "Post"}
          </button>
        </div>

        <input
          ref={inputRef}
          type="file"
          accept="image/*"
          capture="camera"
          onChange={handleFileChange}
          style={{ display: "none" }}
        />
      </div>
    </div>
  );
}

export default Camera;
