import React, { useState, useEffect } from 'react';
import MediaFeed from './MediaFeed';
import './App.css';
import SignUpForm from './SignUpForm';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { firestore, auth, getAuth, signInWithCustomToken, onAuthStateChanged } from './firebase-config'; // Import your config
import EditProfileModal from './EditProfileModal';
import SMSForm from './SMSForm';
import './index.css';
import UploadPrompts from './UploadPrompts';
import Camera from './Camera';
import EventHub from './EventHub';
import ItineraryPage from './ItineraryPage';
import SavedMediaFeed from './SavedMediaFeed';
import EventCode from './EventCode';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

function App() {
  const [activeTab, setActiveTab] = useState('');
  const [users, setUsers] = useState([]);
  //const [currentPromptIndex, setCurrentPromptIndex] = useState(0);
  //const vapidKey = 'BEAKYF6KNgTsSG9kU-hg02-Sx1IMVTwHp7FGIWa3lZtoDhR2054YrtX_mrVdjMSINgXMZhwFTrkbyTtqtmPc9mE';
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [userId, setUserId] = useState('');
  const [mediaFeed, setMediaFeed] = useState([]);
  const [hasEventExtension, setHasEventExtension] = useState(false);
  const [isHost, setIsHost] = useState(false);
  const [eventCode, setEventCode] = useState('');

  useEffect(() => {
    const pathname = window.location.pathname;
    if (pathname && pathname !== '/') {
      setHasEventExtension(true);
      setEventCode(pathname);
    }
  }, []);

  const handleEventCodeSubmit = (eventCode) => {
    console.log('Redirecting to event:', eventCode);  
    window.location.href = `/${eventCode}`;
  };

  const signInWithCustomLink = (token) => {
    const auth = getAuth();
    signInWithCustomToken(auth, token)
      .then((result) => {
        console.log('User signed in:', result.user);
      })
      .catch((error) => {
        console.error('Error signing in with custom token:', error);
      });
  };

  // On component mount, check for the token in the URL
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token'); // Get the custom token from the URL
    if (token) {
      signInWithCustomLink(token); // If token exists, sign the user in
    }
  }, []); // Empty dependency array to run once on component mount


  // Listen for authentication changes and set the user
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUsers(user);
        setUserId(user.uid); // Set the user ID
        setActiveTab('mediafeed');
        
        if (eventCode) {
          try {
            // Check if the user exists in the event's profiles collection
            const userDocRef = doc(firestore, `events${eventCode}/profiles`, user.uid);
            const userDocSnapshot = await getDoc(userDocRef);
  
            if (!userDocSnapshot.exists()) {
              // Add the user to the profiles collection for this event
              await setDoc(userDocRef, {
                displayName: user.displayName || 'Unknown User',
                photoURL: user.photoURL || '',
                userId: user.uid,
              });
              console.log(`User added to profiles for event: ${eventCode}`);
            } else {
              console.log(`User already exists in profiles for event: ${eventCode}`);
            }
          } catch (error) {
            console.error('Error adding user to profiles collection:', error.message);
          }
        }

      } else {
        setUsers(null);
        setActiveTab('signup');
      }
    });

    return () => unsubscribe();
  }, [eventCode]);  // Listen for authentication changes and set the user

  const addMedia = (newMedia) => {
    setMediaFeed([...mediaFeed, newMedia]); // Add new media to the feed
  };

  // Function to handle tab click
  const handleTabClick = (tab) => {
    setActiveTab(tab);
    // Scroll to the top of the page smoothly
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Adds smooth scrolling
    });
  };

  const handleProfileClick = () => {
    setIsModalOpen(true); // Open the modal when the profile is clicked
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal
  };

  // Function to handle phone number change
  const handlePhoneNumberChange = (number) => {
    setPhoneNumber(number); // Update the phone number state
  };

  const handleCloseCamera = () => {
    setActiveTab('mediafeed');
  };

  useEffect(() => {
    const retrieveHostField = async (userId, eventCode) => {
      try {
        const userDocRef = doc(firestore, `events${eventCode}/profiles`, userId);
        const userDocSnapshot = await getDoc(userDocRef);
        if (userDocSnapshot.exists()) {
          const host = userDocSnapshot.data().host;
          setIsHost(host);
          console.log('Host:', host);
        }
      } catch (error) {
        console.error('Error retrieving host field:', error);
      }
    };

    if (userId && eventCode) {
      retrieveHostField(userId, eventCode);
    }
  }, [userId, eventCode]);

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route
            path="/"
            element={
              hasEventExtension ? (
                <Navigate to="/event-code" />
              ) : (
                <EventCode onSubmit={handleEventCodeSubmit} />
              )
            }
          />

          <Route path="/event-code" element={<EventCode />} />

          <Route
            path="*"
            element={
              <>
                <header className="App-header">
                  <button
                    className={`tab-button ${activeTab === 'mediafeed' ? 'active' : ''}`}
                    onClick={() => window.location.reload()} // Refresh the page when clicked
                  >
                  {/* <img
                    src="\snappo.png"
                  /> */}
                  <span><h1>snappo</h1></span>
                  </button>  

                  <div className="header-right">

                    {(isHost === true) && (
                      <button className={`tab-button ${activeTab === 'eventhub' ? 'active' : ''}`} 
                      onClick={() => handleTabClick('eventhub')}>
                        <span className={`${activeTab === 'eventhub' ? 'material-symbols-outlined' : 'material-symbols-outlined'} large-icon`}>
                          event_note
                        </span>
                      </button>
                    )}
                    {/*           
                    {(userId === 'Nf2mxjCt6aTsgYNhVdG7eMIdgZO2' || userId === 'YOBAtkmQrdaJohjsOn0hOqeQFZ62') && (
                    <button className={`tab-button ${activeTab === 'SMS' ? 'active' : ''}`} onClick={() => handleTabClick('SMS')}>
                      <span className={`${activeTab === 'SMS' ? 'material-icons' : 'material-icons-outlined'} large-icon`}>
                        sms
                      </span>
                    </button>
                    )} */}

                    {users && users.photoURL ? (
                      <img
                        src={users.photoURL}
                        alt={users.displayName || 'User'}
                        className="profile-picture"
                        onClick={handleProfileClick} // Make the profile picture clickable
                        style={{ cursor: 'pointer' }}
                      />
                    ) : (
                      // Show a placeholder if no profile picture is available
                      <span
                        className="material-icons large-icon"
                        onClick={handleProfileClick} // Make placeholder icon clickable
                        style={{ cursor: 'pointer' }}
                      >
                        account_circle
                      </span>
                    )}

                  </div>
                </header>
                <div className="tabs">
                  <button className={`tab-button ${activeTab === 'mediafeed' ? 'active' : ''}`} onClick={() => handleTabClick('mediafeed')}>
                    <span className={`${activeTab === 'mediafeed' ? 'material-icons' : 'material-icons-outlined'} large-icon`}>
                      home
                    </span>
                  </button>
                  <button className={`tab-button ${activeTab === 'camera' ? 'active' : ''}`} onClick={() => handleTabClick('camera')}>
                    <span className={`${activeTab === 'camera' ? 'material-icons' : 'material-icons-outlined'} large-icon`}>
                      camera
                    </span>
                  </button>
                  <button className={`tab-button ${activeTab === 'savedmediafeed' ? 'active' : ''}`} onClick={() => handleTabClick('savedmediafeed')}>
                    <span className="material-icons-outlined large-icon">
                      {activeTab === 'savedmediafeed' ? 'bookmark' : 'bookmark_border'}
                    </span>
                  </button>
                  <button className={`tab-button ${activeTab === 'itinerary' ? 'active' : ''}`} onClick={() => handleTabClick('itinerary')}>
                    <span className={`${activeTab === 'itinerary' ? 'material-icons' : 'material-icons-outlined'} large-icon`}>
                      format_list_bulleted
                    </span>
                  </button>
                  {/* {(userId === 'Nf2mxjCt6aTsgYNhVdG7eMIdgZO2' || userId === 'YOBAtkmQrdaJohjsOn0hOqeQFZ62') && (
                    <button className={`tab-button ${activeTab === 'SMS' ? 'active' : ''}`} onClick={() => handleTabClick('SMS')}>
                      <span className={`${activeTab === 'SMS' ? 'material-icons' : 'material-icons-outlined'} large-icon`}>
                        sms
                      </span>
                    </button>
                  )} */}
                  {/* {(userId === 'Nf2mxjCt6aTsgYNhVdG7eMIdgZO2' || userId === 'YOBAtkmQrdaJohjsOn0hOqeQFZ62') && (
                    <button className={`tab-button ${activeTab === 'uploadprompts' ? 'active' : ''}`} 
                    onClick={() => handleTabClick('uploadprompts')}>
                      <span className={`${activeTab === 'uploadprompts' ? 'material-icons' : 'material-icons-outlined'} large-icon`}>
                        event_note
                      </span>
                    </button>
                  )} */}
                </div>

                <div className="tab-content">
                  {/* {activeTab === 'signup' && <SignUpForm onSignUpSuccess={addUser}/>} */}
                  {activeTab === 'camera' && (
                    <Camera 
                    addMedia={addMedia}
                    onClose={handleCloseCamera}
                    />
                  )}
                  {activeTab === 'mediafeed' && <MediaFeed/>}

                  {activeTab === 'savedmediafeed' && <SavedMediaFeed/>}
                  
                  {activeTab === 'itinerary' && <ItineraryPage/>}

                  {activeTab === 'signup' && <SignUpForm/>}

                  {(isHost === true) && activeTab === 'SMS' && (
                    <SMSForm phoneNumber={phoneNumber} onPhoneNumberChange={handlePhoneNumberChange} />
                  )}
                  {(isHost === true) && activeTab === 'uploadprompts' && (
                    <UploadPrompts />
                  )}
                  {(isHost === true) && activeTab === 'eventhub' && (
                    <EventHub />
                  )}
                </div>
                {isModalOpen && (<EditProfileModal user={users} onClose={handleCloseModal} />)}
              </>
            }
          />
        </Routes>
       </div>
      </Router>  
  );
}

export default App;
