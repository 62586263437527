//
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import { getMessaging, getToken } from 'firebase/messaging';
import { initializeApp } from 'firebase/app';


const firebaseConfig = {
  apiKey: "AIzaSyAhou6WpxXSlUxmfqzpZxeYyYPXevJXDGs",
  authDomain: "vip-feed-d30d0.firebaseapp.com",
  databaseURL: "https://vip-feed-d30d0-default-rtdb.firebaseio.com",
  projectId: "vip-feed-d30d0",
  storageBucket: "vip-feed-d30d0.appspot.com",
  messagingSenderId: "603002084929",
  appId: "1:603002084929:web:6ad9f3e833ac311b4f70c0",
  measurementId: "G-H627EZ30GH",
};

// Initialize Firebase
initializeApp(firebaseConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// const vapidKey = 'BEAKYF6KNgTsSG9kU-hg02-Sx1IMVTwHp7FGIWa3lZtoDhR2054YrtX_mrVdjMSINgXMZhwFTrkbyTtqtmPc9mE';

// // Register the service worker
// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker.register('/firebase-messaging-sw.js')
//     .then((registration) => {
//       console.log('Service Worker registered with scope:', registration.scope);
      
//       // Initialize Firebase Messaging and request the token
//       const messaging = getMessaging();
//       return getToken(messaging, { vapidKey: vapidKey });
//     })
//     .then((currentToken) => {
//       if (currentToken) {
//         console.log('FCM Token:', currentToken);
//         // Send the token to your server or save it
//       } else {
//         console.log('No registration token available. Request permission to generate one.');
//       }
//     })
//     .catch((error) => {
//       console.error('An error occurred while retrieving token. ', error);
//     });
// }

// Measure performance
reportWebVitals();

